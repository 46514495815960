<template>
  <div class="common-time">
    <el-form :inline="true">
      <el-form-item label="查询时间">
        <el-date-picker
          v-model="signalTime"
          @change="pickerChange"
          value-format="yyyy-MM-dd"
          type="daterange"
          style="width: 280px"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="">
        <el-radio-group border @change="change" v-model="timeType" size="small">
          <el-radio-button :label="0">今日</el-radio-button>
          <el-radio-button :label="1">昨日</el-radio-button>
          <el-radio-button :label="6">7日</el-radio-button>
          <el-radio-button :label="13">14日</el-radio-button>
          <el-radio-button :label="29">30日</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "commonTime",
  props: ["timeData"],
  data() {
    return {
      signalTime: [],
      timeType: 0,
    };
  },
  mounted() {
    if (this.timeType == 1) {
      this.$emit("changTime", [
        moment().subtract(1, "day").format("YYYY-MM-DD") + " 00:00:00",
        moment().subtract(1, "day").format("YYYY-MM-DD") + " 23:59:59",
      ]);
      this.signalTime = [
        moment().subtract(1, "day").format("YYYY-MM-DD"),
        moment().subtract(1, "day").format("YYYY-MM-DD"),
      ];
    } else {
      this.$emit("changTime", this.$getDateRange(this.timeType, "day"));
      this.signalTime = [
        this.$getDateRange(this.timeType, "day")[0],
        this.$getDateRange(this.timeType, "day")[1],
      ];
    }
  },
  methods: {
    change() {
      this.signalTime = [];
      if (this.timeType == 1) {
        this.$emit("changTime", [
          moment().subtract(1, "day").format("YYYY-MM-DD") + " 00:00:00",
          moment().subtract(1, "day").format("YYYY-MM-DD") + " 23:59:59",
        ]);
        this.signalTime = [
          moment().subtract(1, "day").format("YYYY-MM-DD"),
          moment().subtract(1, "day").format("YYYY-MM-DD"),
        ];
      } else {
        this.$emit("changTime", this.$getDateRange(this.timeType, "day"));
        this.signalTime = [
          this.$getDateRange(this.timeType, "day")[0],
          this.$getDateRange(this.timeType, "day")[1],
        ];
      }
    },
    pickerChange(val) {
      if (this.signalTime && this.signalTime[1]) {
        this.timeType = undefined;
        this.$emit("changTime", [
          this.signalTime[0] + " 00:00:00",
          this.signalTime[1] + " 23:59:59",
        ]);
      } else {
        this.timeType = 0;
        this.change();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.common-time {
  display: inline-block;
}
</style>