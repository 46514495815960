import gateWayConfig from "@/config/gateway.config";
import { callApi } from "@/api/callApi";

// 获取各个接口地址
import apisOld from "@/api/apisOld";

function fetchData(opts) {
  const { url, method, showLoading, isVH, params } = opts;

  const baseApi = getBaseurl(opts);
  const headers = getHeaders(opts);
  let axios = callApi(baseApi, headers);

  return axios({
    url: apisOld[url],
    method: method,
    data: params,
    // headers: {
    //   "content-type": "application/json",
    // },
  });
}
function getBaseurl(opts) {
  let baseApi = "";

  if (opts.isBase) baseApi = gateWayConfig.BASE_URL;
  if (opts.isAddReturn) baseApi = gateWayConfig.ADDRETURN_URL;
  if (opts.isGate) baseApi = gateWayConfig.GATEWAY_URL;
  if (opts.isPhp) baseApi = gateWayConfig.PHP_URl;
  if (opts.isCrm) baseApi = gateWayConfig.CRM_URl;
  if (opts.isVH) baseApi = gateWayConfig.V_HOME;
  if (opts.isOnline) baseApi = gateWayConfig.ONLINE;
  if (opts.isCRMAPI) baseApi = gateWayConfig.CRMAPI;
  if (opts.isPRODUCT) baseApi = gateWayConfig.PRODUCTAPI;
  if (opts.isCHECKMISS) baseApi = gateWayConfig.CHECKMISS;
  if (opts.isTiTokApi) baseApi = gateWayConfig.TITOKAPI;

  return baseApi;
}
function getHeaders(opts) {
  let headers = {};

  // 合并headers
  Object.assign(headers, opts.headers)

  if (!opts.isLogin) {
    let token = JSON.parse(localStorage.getItem("token"));
    let account = JSON.parse(localStorage.getItem("account"));
    if (!token) _this.$router.push({ path: "/login" });
    else {
      headers["token"] = token;
      headers["userInfo"] = account;
      headers["x-login-session"] = account;
      if (opts.isGate) {
        headers["wow-login-user"] = localStorage.getItem("wow-login-user");
      }

      const newRole = localStorage.getItem("deptLevel");
      let deptLevel = 0;
      if (newRole === "null") {
        deptLevel = 0;
      } else if (deptLevel === "admin") {
        deptLevel = "admin";
      } else {
        deptLevel = Number(localStorage.getItem("deptLevel"));
      }
      if (opts.needId) {
        headers["X-Token"] =
          deptLevel === 1 || deptLevel === 2
            ? localStorage.getItem("tuserPartId")
            : localStorage.getItem("id");
      }
      if (opts.isUserType) {
        headers["X-User-Type"] =
          deptLevel === 1 || deptLevel === 2 ? "T_USER_PART" : "T_USER";
      }
    }
  }
  if (opts.jdHeader) headers["logisticsId"] = opts.jdHeader;
  if (opts.XUser) {
    headers["X-User"] = opts.XUser;
  }
  if (opts.XToken) {
    headers["X-Token"] = localStorage.getItem("tuserPartId");
  }

  headers["sid"] = "4c3adb4d-9190-4447-969a-9ac40af19d9b";
  headers["x-login-session"] = localStorage.getItem("x-login-session");
  if (opts.isGate) {
    headers["wow-login-user"] = localStorage.getItem("wow-login-user");
  }

  return headers
}

export { fetchData };
