<template>
  <div class="">
    <div class="top">
      <commonTime @changTime="changTime"></commonTime>
      <commonArea
        :provinceChild="province"
        :cityChild="city"
        :areaChild="area"
        @changeArea="changeArea"
        :areaInfoChild="areaInfo"
        :showAreaInfo="false"
        @search=""
      ></commonArea>
    </div>
    <div class="table">
      <p>宴席数据</p>
      <el-table :data="tableData" fit style="width: 100%" tooltip-effect="dark">
        <el-table-column
          prop="group_name"
          label="指标名称"
          min-width="220"
        ></el-table-column>
        <el-table-column prop="banquet_count" label="执行场次" min-width="110">
          <template slot="header" slot-scope="scope">
            执行场次
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区的宴席执行场次"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="order_count" label="订单数" min-width="110">
          <template slot="header" slot-scope="scope">
            订单数
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区执行宴席的订单数"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="order_price" label="订单金额" min-width="110">
          <template slot="header" slot-scope="scope">
            订单金额
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区执行宴席的订单金额"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <span>{{ _thousands(scope.row.order_price) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="total_num" label="订单瓶数" min-width="110">
          <template slot="header" slot-scope="scope">
            订单瓶数
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区执行宴席的订单酒瓶数"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="open_num" label="开瓶数" min-width="110">
          <template slot="header" slot-scope="scope">
            开瓶数
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区执行宴席的开瓶数"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="open_num_rate" label="开瓶率" min-width="110">
          <template slot="header" slot-scope="scope">
            开瓶率
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区的（开瓶数/订单瓶数）*100%"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.open_num_rate + "%" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="free_num" label="免单瓶数" min-width="110">
          <template slot="header" slot-scope="scope">
            免单瓶数
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区执行宴席的免单瓶数"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="free_price" label="免单金额" min-width="110">
          <template slot="header" slot-scope="scope">
            免单金额
            <el-popover placement="top" title="" width="300" trigger="hover" content="筛选时间段&地区执行宴席的免单金额">
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
          <template  slot-scope="scope">
            <span>{{_thousands(scope.row.free_price)}}</span>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="table">
      <p>小品会数据</p>
      <el-table
        :data="tableDataM"
        fit
        style="width: 100%"
        tooltip-effect="dark"
      >
        <el-table-column
          prop="group_name"
          label="指标名称"
          min-width="110"
        ></el-table-column>
        <el-table-column prop="apply_count" label="执行场次" min-width="110">
          <template slot="header" slot-scope="scope">
            执行场次
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区的小品会执行场次"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="apply_money" label="消耗费用" min-width="110">
          <template slot="header" slot-scope="scope">
            消耗费用
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区执行小品会的消耗费用"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="sign_user_count"
          label="签到人数"
          min-width="110"
        >
          <template slot="header" slot-scope="scope">
            签到人数
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区执行小品会的签到人数"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table">
      <p>联谊会数据</p>
      <el-table
        :data="tableDataFM"
        fit
        style="width: 100%"
        tooltip-effect="dark"
      >
        <el-table-column
          prop="group_name"
          label="指标名称"
          min-width="110"
        ></el-table-column>
        <el-table-column prop="apply_count" label="执行场次" min-width="110">
          <template slot="header" slot-scope="scope">
            执行场次
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区的联谊会执行场次"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="apply_money" label="消耗费用" min-width="110">
          <template slot="header" slot-scope="scope">
            消耗费用
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区执行联谊会的消耗费用"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="sign_user_count"
          label="签到人数"
          min-width="110"
        >
          <template slot="header" slot-scope="scope">
            签到人数
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区执行联谊的签到人数"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { fetchData } from './service/dasboard'

export default {
  components: {},
  data() {
    return {
      timeData: [],
      tableData: [],
      tableDataM: [],
      tableDataFM: [],
      province: "",
      city: "",
      area: "",
      areaInfo: "",
    };
  },
  computed: {},
  methods: {
    changeArea(province, city, area, areaInfo) {
      this.province = province;
      this.city = city;
      this.area = area;
      this.getData("MEETING");
      this.getData("FRIEND_MEETING");
      this.getBanquetStatics();
    },
    changTime(arr) {
      this.timeData = arr;
      this.getData("MEETING");
      this.getData("FRIEND_MEETING");
      this.getBanquetStatics();
    },
    // 获取表格
    getData(type) {
      let _this = this;
      _this.tableData = [];
      let opts = {
        url: "getApplyStatics",
        method: "GET",
        showLoading: true,
        isVH: true,
        params: {
          apply_type: type,
        },
      };
      if (_this.timeData != null && _this.timeData.length == 2) {
        opts.params.start_time = _this.timeData[0];
        opts.params.end_time = _this.timeData[1];
      }
      if (this.province) opts.params.pro_name = this.province;
      if (this.city) opts.params.cit_name = this.city;
      if (this.area) opts.params.area_name = this.area;
      fetchData(opts).then((res) => {
        if (res.code == 0) {
          if (type == "MEETING") {
            _this.tableDataM = [
              Object.assign({}, res.data, { group_name: "小品会" }),
            ];
          } else if (type == "FRIEND_MEETING") {
            _this.tableDataFM = [
              Object.assign({}, res.data, { group_name: "联谊会" }),
            ];
          }
        }
      });
    },
    getBanquetStatics() {
      let _this = this;
      _this.tableData = [];
      let opts = {
        url: "getBanquetStatics",
        method: "GET",
        showLoading: true,
        isVH: true,
        params: {},
      };
      if (_this.timeData != null && _this.timeData.length == 2) {
        opts.params.start_time = _this.timeData[0];
        opts.params.end_time = _this.timeData[1];
      }
      if (this.province) opts.params.pro_name = this.province;
      if (this.city) opts.params.cit_name = this.city;
      if (this.area) opts.params.area_name = this.area;
      fetchData(opts).then((res) => {
        if (res.code == 0) {
          this.tableData = [
            Object.assign({}, res.data, { group_name: "宴席" }),
          ];
        }
      });
    },
  },
  created() {},
  mounted() {},
  watch: {},
};
</script>
<style lang='scss' scoped>
.main-content {
  margin: 20px;
  background-color: white;
  width: calc(100% - 40px);
  padding: 35px;
}

.card {
  display: flex;
  justify-content: space-between;

  & > div {
    width: 18%;
    background-color: rgb(211, 208, 208);
    padding: 10px;
    line-height: 40px;
  }

  .strong {
    text-align: center;
    font-size: 24px;
  }
}
.top {
  background: #ffffff;
  padding: 20px 24px 0;
}
.table {
  background: #ffffff;
  padding: 20px 24px;
  margin-top: 24px;
}
</style>
