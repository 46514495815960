<template>
  <div class="generalSituation">
    <div class="top">
      <commonTime @changTime="changTime"></commonTime>
    </div>
    <div class="card">
      <div style="flex-shrink: 0">
        <p class="popover">
          动销金额(元）<el-popover
            placement="top"
            title=""
            width="300"
            trigger="hover"
            content="筛选时间段内的动销金额"
          >
            <i
              slot="reference"
              style="font-size: 16px"
              class="el-icon-warning-outline"
            ></i>
          </el-popover>
        </p>
        <p class="strong">{{ _thousands(moneyData.sales_amount) }}</p>
      </div>
      <div>
        <p class="popover">
          宴席举办场次<el-popover
            placement="top"
            title=""
            width="300"
            trigger="hover"
            content="筛选时间段内宴席进行场次"
          >
            <i
              slot="reference"
              style="font-size: 16px"
              class="el-icon-warning-outline"
            ></i>
          </el-popover>
        </p>
        <p class="strong">{{ _thousands(banquetData.banquet_amount) }}</p>
      </div>
      <div>
        <p class="popover">
          购酒人数<el-popover
            placement="top"
            title=""
            width="300"
            trigger="hover"
            content="筛选时间段内的购酒消费者人数"
          >
            <i
              slot="reference"
              style="font-size: 16px"
              class="el-icon-warning-outline"
            ></i>
          </el-popover>
        </p>
        <p class="strong">{{ _thousands(moneyData.sales_member_count) }}</p>
      </div>
      <div>
        <p class="popover">
          签约网点数量<el-popover
            placement="top"
            title=""
            width="300"
            trigger="hover"
            content="筛选时间段内审核通过的新签约烟酒店/餐饮店数量"
          >
            <i
              slot="reference"
              style="font-size: 16px"
              class="el-icon-warning-outline"
            ></i>
          </el-popover>
        </p>
        <p class="strong">{{ _thousands(pointCount) }}</p>
      </div>
      <div>
        <p class="popover">
          签约经销商数量<el-popover
            placement="top"
            title=""
            width="300"
            trigger="hover"
            content="筛选时间段内审核通过的新签约全渠道经销商/团购经销商/内行品鉴店数量"
          >
            <i
              slot="reference"
              style="font-size: 16px"
              class="el-icon-warning-outline"
            ></i>
          </el-popover>
        </p>
        <p class="strong">{{ _thousands(dealersCount) }}</p>
      </div>
    </div>
    <div class="echart-class">
      <!-- <el-col :span="12"><pie ></pie></el-col> -->
      <div class="bg-w" :span="12">
        <p class="popover">
          动销地域贡献（金额）
          <el-popover
            placement="top"
            title=""
            width="300"
            trigger="hover"
            content="筛选时间段内不同地区的动销金额贡献"
          >
            <i
              slot="reference"
              style="font-size: 16px"
              class="el-icon-warning-outline"
            ></i>
          </el-popover>
        </p>
        <rectangle :data="salesAreas"></rectangle>
      </div>
      <div class="bg-w" :span="12">
        <p class="popover">
          动销产品系列贡献（瓶数）
          <el-popover
            placement="top"
            title=""
            width="300"
            trigger="hover"
            content="筛选时间段内不同产品系列的动销瓶数贡献"
          >
            <i
              slot="reference"
              style="font-size: 16px"
              class="el-icon-warning-outline"
            ></i>
          </el-popover>
        </p>
        <pie :data="salesProduct"></pie>
      </div>
      <div class="bg-w" :span="12">
        <p class="popover">
          动销渠道贡献（金额）
          <el-popover
            placement="top"
            title=""
            width="300"
            trigger="hover"
            content="筛选时间段内不同渠道的动销金额贡献"
          >
            <i
              slot="reference"
              style="font-size: 16px"
              class="el-icon-warning-outline"
            ></i>
          </el-popover>
        </p>
        <piechannel :data="salesChannel"></piechannel>
      </div>
      <!-- <el-col :span="12"><pie ></pie></el-col> -->
    </div>
  </div>
</template>

<script>
import rectangle from "./rectangle.vue";
import pie from "./pie.vue";
import piechannel from "./piechannel.vue";
import commonTime from "./components/commonTime.vue";

import { fetchData } from "./service/dasboard";
export default {
  components: {
    rectangle,
    pie,
    piechannel,
    commonTime,
  },
  data() {
    return {
      timeData: [],
      moneyData: {},
      banquetData: {},
      pointCount: 0,
      dealersCount: 0,
      salesProduct: [],
      salesChannel: [],
      salesAreas: [],
    };
  },
  computed: {},
  methods: {
    changTime(arr) {
      this.timeData = arr;
      this.getMoney();
      this.getBanquet();
      this.getBusiness();
      this.getSalesProduct();
      this.getSalesChannel();
      this.getSalesAreas();
    },
    // 动销金额(元）
    getMoney() {
      let _this = this;
      let opts = {
        url: "overviewSales",
        method: "GET",
        showLoading: true,
        isVH: true,
        params: {},
      };
      if (_this.timeData != null && _this.timeData.length == 2) {
        opts.params.begin_date = _this.timeData[0];
        opts.params.end_date = _this.timeData[1];
      }
      fetchData(opts).then((res) => {
        if (res.code == 0) {
          this.moneyData = res.data;
        }
      });
    },
    // 宴席
    getBanquet() {
      let _this = this;
      let opts = {
        url: "overviewBanquet",
        method: "GET",
        showLoading: true,
        isVH: true,
        params: {},
      };
      if (_this.timeData != null && _this.timeData.length == 2) {
        opts.params.begin_date = _this.timeData[0];
        opts.params.end_date = _this.timeData[1];
      }
      fetchData(opts).then((res) => {
        if (res.code == 0) {
          this.banquetData = res.data;
        }
      });
    },
    //签约
    getBusiness() {
      let _this = this;
      this.pointCount = 0;
      this.dealersCount = 0;
      let opts = {
        url: "overviewBusiness",
        method: "GET",
        showLoading: true,
        isVH: true,
        params: {},
      };
      if (_this.timeData != null && _this.timeData.length == 2) {
        opts.params.begin_date = _this.timeData[0];
        opts.params.end_date = _this.timeData[1];
      }
      fetchData(opts).then((res) => {
        if (res.code == 0) {
          res.data.map((item) => {
            if (item.type == 3 || item.type == 4) {
              this.pointCount += item.number;
            } else if (item.type == 1 || item.type == 2 || item.type == 5) {
              this.dealersCount += item.number;
            }
          });
        }
      });
    },
    // 动销产品系列贡献（瓶数）
    getSalesProduct() {
      let _this = this;
      let opts = {
        url: "sales_product_number",
        method: "GET",
        showLoading: true,
        isVH: true,
        params: {},
      };
      if (_this.timeData != null && _this.timeData.length == 2) {
        opts.params.begin_date = _this.timeData[0];
        opts.params.end_date = _this.timeData[1];
      }
      fetchData(opts).then((res) => {
        if (res.code == 0) {
          this.salesProduct = res.data;
        }
      });
    },
    // 动销渠道贡献（金额）
    getSalesChannel() {
      let _this = this;
      let opts = {
        url: "sales_channel",
        method: "GET",
        showLoading: true,
        isVH: true,
        params: {},
      };
      if (_this.timeData != null && _this.timeData.length == 2) {
        opts.params.begin_date = _this.timeData[0];
        opts.params.end_date = _this.timeData[1];
      }
      fetchData(opts).then((res) => {
        if (res.code == 0) {
          this.salesChannel = res.data.channel;
        }
      });
    },
    // 动销渠道贡献（金额）
    getSalesAreas() {
      let _this = this;
      this.pointCount = 0;
      this.dealersCount = 0;
      let opts = {
        url: "sales_areas",
        method: "GET",
        showLoading: true,
        isVH: true,
        params: {},
      };
      if (_this.timeData != null && _this.timeData.length == 2) {
        opts.params.begin_date = _this.timeData[0];
        opts.params.end_date = _this.timeData[1];
      }
      fetchData(opts).then((res) => {
        if (res.code == 0) {
          this.salesAreas = res.data;
        }
      });
    },
  },
  created() {},
  mounted() {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.generalSituation {
  * {
    box-sizing: border-box;
  }
  .top {
    background: #ffffff;
    padding: 20px 24px 0;
  }
  .card {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    & > div {
      background: #ffffff;
      border: 1px solid #f0f0f0;
      border-radius: 4px;
      height: 117px;
      min-width: 18%;
      padding: 24px;
      .popover {
        display: flex;
        justify-content: space-between;
      }
    }
    .strong {
      //   text-align: center;
      font-size: 30px;
      font-family: Roboto;
      line-height: 35px;
      margin-top: 7px;
    }
  }
  .echart-class {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .bg-w {
      background-color: #fff;
      width: 49%;
      margin-bottom: 24px;
      padding: 24px;
      //   margin-right: 24px;
    }
  }
}
</style>
