<template>
  <div class="chart-col">
    <div ref="chartEl" style="height: 100%; width: 100%"></div>
  </div>
</template>
<script>
/* eslint-disable */

export default {
  data() {
    return {
      echartOption: {
        // backgroundColor: '', //设置整体背景色
        // color: ['#8378EA','#9C97F2','#92C0FF','#EAB9F7','#E78FD4','#DC63AE','#F77294','#F99E82','#FDDB5C','#399FE1','#3BC6E6','#67E1E3','#ACDEBD'],
        color: [
          "#7D1EDA",
          "#F87C4C",
          "#FCD037",
          "#208FFF",
          "#3DC5C4",
          "#F8999D",
          "#42C800",
          "#F64681",
        ],

        // tooltip: {
        //   formatter: '{b} <br/> {c} ({d}%)'
        // },
        legend: {
          type: "scroll",
          orient: "vertical",

          data: [],
        },
        series: [
          {
            type: "pie",
            selectedMode: "single",
            hoverAnimation: false,
            data: [],
            // roseType: 'angle',
            label: {
              show: true,
              align: "right",
              fontSize: 12,
            }, // 设置说明文字位置
          },
        ],
      },
    };
  },
  props: ["data"],
  methods: {
    drawLine() {
      // 内容授权统计
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(this.$refs.chartEl, "light");
      // 绘制图表
      myChart.setOption(this.echartOption);
    },
    // 获取内容授权数据
    getListData() {
      let myChart = echarts.getInstanceByDom(this.$refs.chartEl);
      // myChart.showLoading()
      // myChart.hideLoading()
      let renderDatas = [];
      if (this.data.length > 0) {
        this.data.map((item) => {
          renderDatas.push({
            value: item.count,
            name: `${item.type == "others" ? "其他" : item.type}`,
          });
        });
      }
      myChart.setOption({
        tooltip: {
          formatter: "{b} <br/> {c} ({d}%)",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          align: "left",
          x: "right",
          y: "center",
          itemGap: 10,
          itemWidth: 7,
          itemHeight: 7,
          icon: "circle",
          data: this.data.map((item) => {
            return item.type == "others" ? "其他" : item.type;
          }),
          selected: [],
          // textStyle: {
          //   color: '#fff' // 主标题文字颜色
          // }
        },
        series: [
          {
            type: "pie",
            center: ["40%", "50%"],
            radius: ["50%", "70%"],
            data: renderDatas,
            itemStyle: {
              borderWidth: renderDatas.length > 1 ? 5 : 0, //边框的宽度
              borderColor: "#fff", //边框的颜色
            },
            label: {
              normal: {},
            }, // 设置说明文字位置
            labelLine: {
              normal: {},
            },
          },
        ],
      });
    },
  },
  mounted() {
    this.drawLine();
    let myChart = echarts.getInstanceByDom(this.$refs.chartEl);
    window.addEventListener("resize", () => {
      myChart.resize();
    });
  },
  watch: {
    data: {
      handler(newName, oldName) {
        if (newName) {
          this.$nextTick(() => {
            this.getListData();
          });
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      myChart.resize();
    });
  },
};
</script>
<style lang="scss" scope>
.chart-col {
  height: 400px;
}
</style>
