<template>
  <div class="statistic-col">
    <div ref="chartEl" style="height: 100%; width: 100%"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      barColor: [
        "#722ED1",
        "#FF8559",
        "#FFD259",
        "#1890FF",
        "#13C2C2",
        "#FF9F9F",
        "#52C41A",
        "#FE5983",
      ],
      lineColor: [
        "#B077FF",
        "#FFA989",
        "#FFE6A2",
        "#64B5FF",
        "#37F0F0",
        "#FFDFDF",
        "#A6FF7B",
        "#FF97B2",
      ],
      echartOption: {
        // backgroundColor: '', //设置整体背景色
        // color: ['#8378EA','#9C97F2','#92C0FF','#EAB9F7','#E78FD4','#DC63AE','#F77294','#F99E82','#FDDB5C','#399FE1','#3BC6E6','#67E1E3','#ACDEBD'],
        color: [
          "#7D1EDA",
          "#F87C4C",
          "#FCD037",
          "#208FFF",
          "#3DC5C4",
          "#F8999D",
          "#42C800",
          "#F64681",
        ],

        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [],
            type: "bar",
          },
        ],
      },
    };
  },
  props: ["data"],
  methods: {
    drawLine() {
      // 内容授权统计
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(this.$refs.chartEl);
      // 绘制图表
      myChart.setOption(this.echartOption);
    },
    // 获取内容授权数据
    getListData() {
      let myChart = echarts.getInstanceByDom(this.$refs.chartEl);
      myChart.clear();
      let renderDatas = [];
      let lineDatas = [];
      let xName = [];
      let legendName = [];
      for (let index = 0; index < this.data.length; index++) {
        xName = [];
        legendName.push(this.data[index].name);
        legendName.push(this.data[index].name);
        let item = [];
        let itemLine = [];
        for (let x = 0; x < this.data[index].tableData.length; x++) {
          item.push(this.data[index].tableData[x].user_count);
          itemLine.push(this.data[index].tableData[x].order_count);
          xName.push(this.data[index].tableData[x].group_name);
        }
        renderDatas.push({
          itemStyle: {
            color:
              index >= this.barColor.length
                ? this.barColor[index - this.barColor.length]
                : this.barColor[index],
          },
          name: this.data[index].name,
          type: "bar",
          data: item,
        });
        lineDatas.push({
          itemStyle: {
            color:
              index >= this.lineColor.length
                ? this.lineColor[index - this.lineColor.length]
                : this.lineColor[index],
          },
          name: this.data[index].name,
          type: "line",
          yAxisIndex: 1,
          data: itemLine,
        });
      }
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter(params) {
            var res = "<div><p>" + params[0].axisValue + "</p></div>";
            for (var i = 0; i < params.length; i++) {
              res +=
                "<p>" +
                params[i].marker +
                params[i].seriesName +
                `${params[i].seriesType == "bar" ? "付费人数" : "付费订单数"}` +
                ":" +
                params[i].data +
                "</p>";
            }
            return res;
          },
        },
        title: {
          subtext: "付费人数与付费订单数",
        },
        legend: {
          data: legendName,
        },
        xAxis: {
          type: "category",
          data: xName,
          axisPointer: {
            type: "shadow",
          },
          axisLabel: {
            // color:'#6c706f',
            interval: 0,
            //  rotate:25,
          },
        },
        yAxis: [
          {
            type: "value",
            name: "人",
          },
          {
            type: "value",
            name: "单",
          },
        ],
        series: [...renderDatas, ...lineDatas],
      });
    },
  },
  mounted() {
    this.drawLine();
    let myChart = echarts.getInstanceByDom(this.$refs.chartEl);
    window.addEventListener("resize", () => {
      myChart.resize();
    });
  },
  watch: {
    data: {
      handler(newName, oldName) {
        this.$nextTick(() => {
          this.getListData();
        });
      },
      deep: true,
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      myChart.resize();
    });
  },
};
</script>
<style lang="scss" scope>
.statistic-col {
  height: 600px;
}
</style>
