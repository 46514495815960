<template>
  <div class="chart-col">
    <div ref="chartEl" style="height: 100%; width: 100%"></div>
  </div>
</template>
<script>
/* eslint-disable */

export default {
  data() {
    return {
      allmoney: 0,
      echartOption: {
        color: [
          "#8378EA",
          "#9C97F2",
          "#92C0FF",
          "#EAB9F7",
          "#E78FD4",
          "#DC63AE",
          "#F77294",
          "#F99E82",
          "#FDDB5C",
          "#399FE1",
          "#3BC6E6",
          "#67E1E3",
          "#ACDEBD",
        ],

        series: [
          {
            type: "treemap",
            roam: false,
            data: [],
          },
        ],
      },
    };
  },
  props: ["data"],
  methods: {
    drawLine() {
      // 内容授权统计
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(this.$refs.chartEl, "light");
      // 绘制图表
      myChart.setOption(this.echartOption);
    },
    // 获取内容授权数据
    getListData() {
      let myChart = echarts.getInstanceByDom(this.$refs.chartEl);
      let renderDatas = [];
      this.allmoney = 0;
      if (this.data.length > 0) {
        this.data.map((item) => {
          this.allmoney += item.total_price / 1;
          renderDatas.push({
            value: item.total_price,
            name: `${item.province == "others" ? "其他" : item.province}`,
          });
        });
      }
      myChart.setOption({
        tooltip: {
          // formatter: '{b} <br/> {c}'
          formatter: (info) => {
            // var value = info.value;
            // var treePathInfo = info.treePathInfo;
            // var treePath = [];

            // for (var i = 1; i < treePathInfo.length; i++) {
            //     treePath.push(treePathInfo[i].name);
            // }
            return [
              `<div class="tooltip-title">${info.name} <br/>${info.value} ${(
                (info.value * 100) /
                this.allmoney
              ).toFixed(2)} %</div>`,
            ].join("");
          },
        },
        series: [
          {
            type: "treemap",
            name: "全部",
            roam: false,
            data: renderDatas,
            breadcrumb: {
              emptyItemWidth: 0,
              itemStyle: {
                color: "#fff",
                shadowBlur: 0,
                borderWidth: 1,
                borderColor: "#eee",
                textStyle: {
                  color: "#000",
                },
              },
            },
          },
        ],
      });
    },
  },
  mounted() {
    this.drawLine();
    let myChart = echarts.getInstanceByDom(this.$refs.chartEl);
    window.addEventListener("resize", () => {
      myChart.resize();
    });
  },
  watch: {
    data: {
      handler(newName, oldName) {
        if (newName) {
          this.$nextTick(() => {
            this.getListData();
          });
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      myChart.resize();
    });
  },
};
</script>
<style lang="scss" scope>
.chart-col {
  height: 400px;
}
</style>
