<template>
  <div class="">
    <div class="top">
      <commonTime @changTime="changTime"></commonTime>
      <commonArea
        :provinceChild="province"
        :cityChild="city"
        :areaChild="area"
        @changeArea="changeArea"
        :areaInfoChild="areaInfo"
        :showAreaInfo="false"
        @search=""
      ></commonArea>
    </div>
    <el-table
      class="table"
      :data="tableData"
      fit
      style="width: 100%"
      tooltip-effect="dark"
    >
      <el-table-column
        prop="series_name"
        label="销售店铺"
        min-width="220"
      ></el-table-column>
      <el-table-column
        prop="series_sale_price"
        label="动销金额"
        min-width="220"
      >
        <template slot-scope="scope">
          <span>{{ _thousands(scope.row.series_sale_price) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="sale_price_percent"
        label="销售金额占比"
        min-width="110"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.sale_price_percent + "%" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="series_sale_count"
        label="销售瓶数"
        min-width="110"
      >
        <template slot-scope="scope">
          <span>{{ _thousands(scope.row.series_sale_count) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="sale_count_percent"
        label="销售瓶数占比"
        min-width="110"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.sale_count_percent + "%" }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { fetchData } from './service/dasboard'

export default {
  components: {},
  data() {
    return {
      timeData: [],
      tableData: [],
      province: "",
      city: "",
      area: "",
      areaInfo: "",
    };
  },
  computed: {},
  methods: {
    changeArea(province, city, area, areaInfo) {
      this.province = province;
      this.city = city;
      this.area = area;
      this.areaInfo = areaInfo;
      this.getData();
    },
    changTime(arr) {
      this.timeData = arr;
      this.getData();
    },
    // 获取表格
    getData() {
      let _this = this;
      _this.tableData = [];
      let opts = {
        url: "getOrdersGoodsStatics",
        method: "GET",
        showLoading: true,
        isVH: true,
        params: {},
      };
      if (_this.timeData != null && _this.timeData.length == 2) {
        opts.params.start_time = _this.timeData[0];
        opts.params.end_time = _this.timeData[1];
      }
      if (this.province) opts.params.consu_pro_name = this.province;
      if (this.city) opts.params.consu_cit_name = this.city;
      if (this.area) opts.params.consu_area_name = this.area;
      fetchData(opts).then((res) => {
        if (res.code == 0) {
          _this.tableData.push(
            Object.assign({}, res.data.calulate, { series_name: "合计" })
          );
          _this.tableData = _this.tableData.concat(res.data.result);
        }
      });
    },
  },
  created() {},
  mounted() {},
  watch: {},
};
</script>
<style lang='scss' scoped>
.main-content {
  margin: 20px;
  background-color: white;
  width: calc(100% - 40px);
  padding: 35px;
}

.card {
  display: flex;
  justify-content: space-between;

  & > div {
    width: 18%;
    background-color: rgb(211, 208, 208);
    padding: 10px;
    line-height: 40px;
  }

  .strong {
    text-align: center;
    font-size: 24px;
  }
}
.top {
  background: #ffffff;
  padding: 20px 24px 0;
}
.table {
  background: #ffffff;
  padding: 20px 24px;
  margin-top: 24px;
}
</style>
