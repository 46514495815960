<template>
  <div class="dashboard">
    <div class="dashboard-top">
      <span @click="radio = 1" :class="{ select: radio == 1 }">概况</span>
      <span @click="radio = 2" :class="{ select: radio == 2 }">渠道动销</span>
      <span @click="radio = 3" :class="{ select: radio == 3 }">渠道动作</span>
      <span @click="radio = 4" :class="{ select: radio == 4 }">渠道签约</span>
      <span @click="radio = 5" :class="{ select: radio == 5 }">商城动销</span>
      <span @click="radio = 6" :class="{ select: radio == 6 }">商品动销</span>
    </div>
    <div v-if="loadEcharts" class="dashboard-main">
      <generalSituation v-if="radio == 1"></generalSituation>
      <channelPin v-if="radio == 2"></channelPin>
      <channelAction v-if="radio == 3"></channelAction>
      <channelSigning v-if="radio == 4"></channelSigning>
      <mallPin v-if="radio == 5"></mallPin>
      <commodityPin v-if="radio == 6"></commodityPin>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import Vue from "vue";
import commonTime from "./components/commonTime.vue";
Vue.prototype._thousands = function (num) {
  if (num) {
    var str = num.toString();
    var reg =
      str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
    return str.replace(reg, "$1,");
  } else {
    return num;
  }
};
Vue.prototype.$getDateRange = function (num, unit) {
  return [
    moment().subtract(num, unit).format("YYYY-MM-DD") + " 00:00:00",
    moment().format("YYYY-MM-DD") + " 23:59:59",
  ];
};
Vue.component("commonTime", commonTime);

import { initAsyn_promise } from "@/utils/loadJs";

import generalSituation from "./generalSituation";
import channelPin from "./channelPin";
import channelAction from "./channelAction";
import channelSigning from "./channelSigning";
import mallPin from "./mallPin";
import commodityPin from "./commodityPin";
export default {
  name: "dashboard",
  components: {
    generalSituation,
    channelPin,
    channelAction,
    channelSigning,
    mallPin,
    commodityPin,
  },
  data() {
    return {
      radio: 1,
      loadEcharts: false,
    };
  },
  mounted() {
    // 前置load依赖echarts
    initAsyn_promise(["echarts", "moment"]).then(() => {
      this.loadEcharts = true;

      if (this.$route.query.type) {
        this.radio = this.$route.query.type;
      }
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.dashboard {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
  .dashboard-top {
    // position: absolute;
    // top: 0;
    background-color: white;
    width: 100%;
    z-index: 1000;
    height: 60px;
    margin-bottom: 20px;
    & > span {
      float: left;
      border-bottom: 2px solid white;
      line-height: 46px;
      font-size: 16px;
      margin: 0 16px;
      cursor: pointer;
    }
    .select {
      color: #1890ff;
      border-bottom: 2px solid #1890ff;
    }
  }
  .dashboard-main {
    // margin: 60px 24px 60px;
  }
}

.tabBar {
  display: flex;
  justify-content: space-between;
}
</style>
