<template>
  <div class="">
    <div class="top"><commonTime @changTime="changTime"></commonTime></div>

    <el-table
      :data="tableData"
      class="table"
      fit
      style="width: 100%"
      tooltip-effect="dark"
    >
      <el-table-column
        prop="point_name"
        label="销售店铺"
        min-width="220"
      ></el-table-column>
      <el-table-column prop="total_sale_price" label="动销金额" min-width="220">
        <template slot-scope="scope">
          <span>{{ _thousands(scope.row.total_sale_price) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="order_count" label="付费订单数" min-width="110">
        <template slot-scope="scope">
          <span>{{ _thousands(scope.row.order_count) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="user_count" label="付费人数" min-width="110">
        <template slot-scope="scope">
          <span>{{ _thousands(scope.row.user_count) }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { fetchData } from './service/dasboard'
import { formDater } from '@/utils/index'

export default {
  components: {},
  data() {
    return {
      timeData: [],
      tableData: [],
    };
  },
  computed: {},
  methods: {
    changTime(arr) {
      this.timeData = arr;
      this.getData();
    },
    // 获取表格
    getData() {
      let _this = this;
      _this.tableData = [];
      let opts = {
        url: "getShopsOrdersStatics",
        method: "GET",
        showLoading: true,
        isVH: true,
        params: {},
      };
      if (_this.timeData != null && _this.timeData.length == 2) {
        opts.params.start_time = formDater(_this.timeData[0]);
        opts.params.end_time = formDater(_this.timeData[1]);
      }
      fetchData(opts).then((res) => {
        if (res.code == 0) {
          _this.tableData.push(
            Object.assign({}, res.data.calulate, { point_name: "合计" })
          );
          _this.tableData = _this.tableData.concat(res.data.result);
        }
      });
    },
  },
  created() {},
  mounted() {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.main-content {
  margin: 20px;
  background-color: white;
  width: calc(100% - 40px);
  padding: 35px;
}

.card {
  display: flex;
  justify-content: space-between;

  & > div {
    width: 18%;
    background-color: rgb(211, 208, 208);
    padding: 10px;
    line-height: 40px;
  }

  .strong {
    text-align: center;
    font-size: 24px;
  }
}
.top {
  background: #ffffff;
  padding: 20px 24px 0;
}
.table {
  background: #ffffff;
  padding: 20px 24px;
  margin-top: 24px;
}
</style>
