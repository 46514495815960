<template>
  <div class="">
    <div class="top">
      <commonTime @changTime="changTime"></commonTime>
      <commonArea
        :provinceChild="province"
        :cityChild="city"
        :areaChild="area"
        @changeArea="changeArea"
        :areaInfoChild="areaInfo"
        :showAreaInfo="false"
        @search=""
      ></commonArea>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" size="small" @click="addMore"
            >添加对比区域</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="reset"
            >对比区域重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- <div class="table" v-for="(item, index) in tableData" :key="index">
      <p>{{item.name}}</p> -->
    <div class="table">
      <el-table
        :data="
          tableData[0] && tableData[0].tableData ? tableData[0].tableData : []
        "
        style="width: 100%"
        tooltip-effect="dark"
      >
        <el-table-column
          fixed="left"
          prop="group_name"
          label="动销场景"
          width="160"
        ></el-table-column>
        <el-table-column prop="total_price" label="动销金额">
          <template slot="header" slot-scope="scope">
            动销金额
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区的动销金额"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
          <el-table-column
            prop="total_price"
            v-for="(item, index) in tableData"
            :key="index"
            min-width="90"
          >
            <template slot="header" slot-scope="scope">
              {{ tableData[index].name }}
              <i
                class="el-icon-circle-close"
                style="cursor: pointer"
                @click="deleteTable(index)"
              ></i>
            </template>
            <template slot-scope="scope">
              <span>{{
                _thousands(tableData[index].tableData[scope.$index].total_price)
              }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="order_count" label="付费订单数" min-width="110">
          <template slot="header" slot-scope="scope">
            付费订单数
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区的付费订单数"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
          <el-table-column
            prop="order_count"
            v-for="(item, index) in tableData"
            :key="index"
            min-width="90"
          >
            <template slot="header" slot-scope="scope">
              {{ tableData[index].name }}
              <i
                class="el-icon-circle-close"
                style="cursor: pointer"
                @click="deleteTable(index)"
              ></i>
            </template>
            <template slot-scope="scope">
              <span>{{
                _thousands(tableData[index].tableData[scope.$index].order_count)
              }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="user_count" label="付费人数" min-width="110">
          <template slot="header" slot-scope="scope">
            付费人数
            <el-popover
              placement="top"
              title=""
              width="300"
              trigger="hover"
              content="筛选时间段&地区的付费消费者人数"
            >
              <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
          </template>
          <el-table-column
            prop="user_count"
            v-for="(item, index) in tableData"
            :key="index"
            min-width="90"
          >
            <template slot="header" slot-scope="scope">
              {{ tableData[index].name }}
              <i
                class="el-icon-circle-close"
                style="cursor: pointer"
                @click="deleteTable(index)"
              ></i>
            </template>
            <template slot-scope="scope">
              <span>{{
                _thousands(tableData[index].tableData[scope.$index].user_count)
              }}</span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
    <bar class="table" :data="tableData"></bar>
    <barAndLine class="table" :data="tableData"></barAndLine>
    <!-- </div> -->
  </div>
</template>

<script>
import bar from "./bar.vue";
import barAndLine from "./barAndLine.vue";
import { fetchData } from './service/dasboard'

// 组件
import commonTime from "./components/commonTime.vue";
import commonArea from "./components/commonArea.vue";

export default {
  components: {
    bar,
    barAndLine,
    commonTime,
    commonArea
  },
  data() {
    return {
      timeData: [],
      tableData: [],
      province: "",
      city: "",
      area: "",
      areaInfo: "",
    };
  },
  computed: {},
  methods: {
    deleteTable(index) {
      console.log(this.tableData[index]);
      if (this.tableData.length <= 1) {
        this.$message({
          message: "不能删除所有区域",
          type: "warning",
        });
      } else {
        this.tableData.splice(index, 1);
      }
    },
    changeArea(province, city, area, areaInfo) {
      this.province = province;
      this.city = city;
      this.area = area;
    },
    reset(province, city, area, areaInfo) {
      this.province = "";
      this.city = "";
      this.area = "";
      this.areaInfo = "";
      this.tableData = [];
      this.getData();
    },
    addMore() {
      this.getData();
    },
    changTime(arr) {
      this.timeData = arr;
      // this.getData()
      for (let index = 0; index < this.tableData.length; index++) {
        this.again(this.tableData[index].name.split("-"), index);
        this.tableData[index].loading = true;
      }
    },
    again(area, index) {
      console.log(area);
      let _this = this;
      let arrayItem = {
        name: "",
        tableData: [],
      };
      let opts = {
        url: "channelPin",
        method: "GET",
        showLoading: true,
        isVH: true,
        params: {},
      };
      if (_this.timeData != null && _this.timeData.length == 2) {
        opts.params.pay_time_begin = _this.timeData[0];
        opts.params.pay_time_end = _this.timeData[1];
      }
      opts.params.province = area[0] == "全国" ? "" : area[0];
      opts.params.city = area[1];
      opts.params.area = area[2];
      fetchData(opts).then((res) => {
        if (res.code == 0) {
          arrayItem.tableData.push(
            Object.assign({}, res.data.total, { group_name: "合计" })
          );
          arrayItem.tableData = arrayItem.tableData.concat(res.data.statistics);
          this.tableData[index].loading = false;
          this.tableData[index].tableData = arrayItem.tableData;
          // this.tableData=[...this.tableData]
        }
      });
    },
    // 获取表格
    getData() {
      let _this = this;
      let arrayItem = {
        name: "",
        tableData: [],
      };
      let opts = {
        url: "channelPin",
        method: "GET",
        showLoading: true,
        isVH: true,
        params: {},
      };
      if (_this.timeData != null && _this.timeData.length == 2) {
        opts.params.pay_time_begin = _this.timeData[0];
        opts.params.pay_time_end = _this.timeData[1];
      }
      if (this.province) opts.params.province = this.province;
      if (this.city) opts.params.city = this.city;
      if (this.area) opts.params.area = this.area;
      fetchData(opts).then((res) => {
        if (res.code == 0) {
          arrayItem.tableData.push(
            Object.assign({}, res.data.total, { group_name: "合计" })
          );
          arrayItem.loading = false;
          arrayItem.tableData = arrayItem.tableData.concat(res.data.statistics);
          arrayItem.name = `${this.province ? this.province : "全国"}${
            this.city ? " - " + this.city : ""
          }${this.area ? " - " + this.area : ""}`;
          this.tableData.push(arrayItem);
        }
      });
    },
  },
  created() {},
  mounted() {
    this.getData();
  },
  watch: {},
};
</script>
<style lang='scss' scoped>
.main-content {
  margin: 20px;
  background-color: white;
  width: calc(100% - 40px);
  padding: 35px;
}

.card {
  display: flex;
  justify-content: space-between;

  & > div {
    width: 18%;
    background-color: rgb(211, 208, 208);
    padding: 10px;
    line-height: 40px;
  }

  .strong {
    text-align: center;
    font-size: 24px;
  }
}
.top {
  background: #ffffff;
  padding: 20px 24px 0;
}
.table {
  background: #ffffff;
  padding: 20px 24px;
  margin-top: 24px;
}
</style>
