<template>
  <div class="common-area">
    <el-form :inline="true">
        <el-form-item>
          <span>{{lableName}}</span>
          <el-select v-model="province" placeholder="请选择"    style="width: 180px" @change="choProvince" :filterable="true" :clearable="true">
            <el-option
              v-for="item in provinces"
              :key="item.id"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="city" placeholder="请选择"    style="width: 206px" @change="choCity" :filterable="true" :clearable="true">
            <el-option
              v-for="item in citys"
              :key="item.value"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="showArea">
          <el-select v-model="area" placeholder="请选择"    style="width: 160px" @change="chengDate" :filterable="true" :clearable="true">
            <el-option
              v-for="item in areas"
              :key="item.value"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="showAreaInfo">
          <el-input v-model="areaInfo" placeholder="请输入详细地址" @input="chengDate" @keyup.enter.native="$emit('search')" style="width: 226px"></el-input>
        </el-form-item>
      </el-form>
  </div>
</template>

<script>
import { fetchData } from '../service/dasboard'

export default {
  name: 'commonArea',
  props: {
    provinceChild: {
      type: String,
      default: null
    },
    cityChild: {
      type: String,
      default: null
    },
    areaChild: {
      type: String,
      default: null
    },
    areaInfoChild: {
      type: String,
      default: null
    },
    showArea: {
      type: Boolean,
      default: true
    },
    showAreaInfo: {
      type: Boolean,
      default: true
    },
    lableName: {
      type: String,
      default: '区域筛选：'
    },
  },
  data() {
    return {
      provinces:[],
      citys:[],
      areas:[],
      province:'',
      city:'',
      area:'',
      areaInfo:''
    }
  },
  watch: {
    provinceChild:function(val) {
      this.province=this.provinceChild
      this.city=this.cityChild
      this.area=this.areaChild
      this.areaInfo=this.areaInfoChild
      // this.provinces=[];
      this.citys=[];
      this.areas=[];
    }
  },
  mounted() {
      this.getArea(1,'1');
      this.province=this.provinceChild
      this.city=this.cityChild
      this.area=this.areaChild
      this.areaInfo=this.areaInfoChild
  },  
  methods: {
      chengDate(){
          let _this = this;
          this.$emit('changeArea',_this.province,_this.city,_this.area,_this.areaInfo)
      },
      getArea(type,num){
          let _this = this;
          let opts = {
            url:"areaList",
            method:"get",
            isBase:true,
            showLoading:false,
            params:{
              parentId:num
            }
          };
          fetchData(opts).then(res=>{
            if(res.resultCode==0){
              res.data = res.data.map(item=>{
                item.value = item.name
                return item
              })
              if(type==1){
                _this.provinces=[]
                // _this.provinces=res.data
                 res.data.map(item=>{
                   if(item.value=='四川省'||item.value=='河南省'){
                    _this.provinces.splice(0,0,item)
                   }else{
                    _this.provinces.push(item)
                   }
                 })
                }
              else if(type==2)_this.citys = res.data;
              else if(type==3)_this.areas = res.data;
            }
          }).catch(err=>{
            console.log(err)
          })
        },
        choProvince(val){
          let _this = this;
          _this.areas = [];
          _this.area = '';
          _this.city = '';
          _this.citys = [];
          this.chengDate()
          for(let i = 0;i < _this.provinces.length;i++){
            if(val==_this.provinces[i].value){
              _this.getArea(2,_this.provinces[i].id);
              break
            }
          }
        },
        choCity(val){
          let _this = this;
          _this.area = '';
          _this.areas = [];
          this.chengDate()
          for(let i = 0;i < _this.citys.length;i++){
            if(val==_this.citys[i].value){
              _this.getArea(3,_this.citys[i].id);
              break
            }
          }
        },
    }
}
</script>
<style lang="scss">
.common-area{
   display: inline-block;
}
</style>