<template>
  <div class="chart-col">
    <div ref="chartEl" style="height: 100%; width: 100%"></div>
  </div>
</template>
<script>
/* eslint-disable */

export default {
  data() {
    return {
      echartOption: {
        color: [
          "#7D1EDA",
          "#F87C4C",
          "#FCD037",
          "#208FFF",
          "#3DC5C4",
          "#F8999D",
          "#42C800",
          "#F64681",
        ],

        // tooltip: {
        //   trigger: 'item',
        //   formatter: '{a} <br/>{b}: {c} ({d}%)'
        // },
        legend: {
          orient: "vertical",
          left: 10,
          data: [],
        },
        series: [
          {
            name: "动销渠道",
            type: "pie",
            selectedMode: "single",
            radius: [0, "30%"],

            label: {
              position: "inner",
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
          {
            name: "动销渠道",
            type: "pie",
            radius: ["40%", "55%"],
            // label: {
            //   formatter: '{b}：{c} {d}% ',
            // },
            data: [],
          },
        ],
      },
    };
  },
  props: ["data"],
  methods: {
    drawLine() {
      // 内容授权统计
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(this.$refs.chartEl, "light");
      // 绘制图表
      myChart.setOption(this.echartOption);
    },
    // 获取内容授权数据
    getListData() {
      let myChart = echarts.getInstanceByDom(this.$refs.chartEl);
      // myChart.showLoading()
      // myChart.hideLoading()
      let renderDatas = [];
      let renderChild = [];
      let name = [];
      let legendName = [];
      for (let index = 0; index < this.data.length; index++) {
        let num = 0;
        let FName = "";
        let color = "";
        switch (this.data[index].type) {
          case "E_SHOP":
            FName = "电商";
            color = this.echartOption.color[index];
            break;
          case "CHANNEL":
            FName = "渠道";
            color = this.echartOption.color[index];
            break;
          case "FLOW_POOL":
            FName = "流量池";
            color = this.echartOption.color[index];
            break;
          case "OHTHERS":
            FName = "其他";
            color = this.echartOption.color[index];
            break;
          default:
            break;
        }

        name.push(FName);
        legendName.push({
          name: FName,
          textStyle: {
            backgroundColor: color,
            padding: 2,
            color: "#fff",
          },
        });
        this.data[index].items.map((item) => {
          num += item.amount;
          legendName.push({
            name: item.name == "其他" ? FName + item.name : item.name,
            textStyle: {
              backgroundColor: color,
              padding: 2,
              color: "#fff",
            },
          });
          renderChild.push({
            value: item.amount,
            name: item.name == "其他" ? FName + item.name : item.name,
          });
        });
        renderDatas.push({
          value: num.toFixed(2),
          name: FName,
        });
      }
      myChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          align: "left",
          x: "right",
          y: "center",
          itemGap: 10,
          itemWidth: 7,
          itemHeight: 7,
          icon: "circle",
          data: legendName,
        },
        series: [
          {
            name: "动销渠道",
            type: "pie",
            selectedMode: "single",
            radius: [0, "50%"],
            center: ["40%", "50%"],
            formatter: "{a} <br/>{b}: {c} ({d}%)",
            label: {
              position: "inner",
              normal: { formatter: "{b}\n{d}%" },
            },
            labelLine: {
              show: false,
            },
            data: renderDatas,
          },
          {
            name: "动销渠道",
            type: "pie",
            radius: ["60%", "75%"],
            center: ["40%", "50%"],
            label: {
              show: false,
              position: "left",
            },
            labelLine: {
              show: false,
              position: "left",
            },
            data: renderChild,
          },
        ],
      });
    },
  },
  mounted() {
    this.drawLine();
    let myChart = echarts.getInstanceByDom(this.$refs.chartEl);
    window.addEventListener("resize", () => {
      myChart.resize();
    });
  },
  watch: {
    data: {
      handler(newName, oldName) {
        if (newName) {
          this.$nextTick(() => {
            this.getListData();
          });
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      myChart.resize();
    });
  },
};
</script>
<style lang="scss" scope>
.chart-col {
  height: 400px;
}
</style>
