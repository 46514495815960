export default {
  login: '/user/login', // 登录
  getUserDepts: '/user-center-final/user/getUserDetailByMobile', // 根据用户id获取用户角色列表
  getLevelByDepId: '/deptAttr/getDeptAttrByDeptId', // 根据部门id获取部门层级
  getProduct: '/product/menu/list', // 商品列表
  getPartId: '/user-center-final/user/getUserByTUserId', //通过t_user_id查询t_user_part_id
  teamTable: '/team/list', // 小组数据
  teamBasic: '/team/detail/basic',// 小组基础信息
  teamTotal: "/team/overview",//小组信息总览
  branchTotal: "/branch/overview",//网点信息总览
  pointInfo: '/branch/list',//网点信息
  pointBasic: '/branch/detail/basic', // 网点基本信息
  movableDetail: '/order/weixin/item/list',//动销明细
  returnMoney: '/branch/detail/rebate/history', //返利历史
  stockInfo: '/branch/detail/stock',//库存信息
  teamList: '/team/menu/list', //小组信息
  dashboard: '/dashboard/get',//首页信息
  pointProduct: '/branch/agree/product/list',//网点下属商品
  submitSale: '/branch/product/replenish',//补货
  distributeRecord: '/branch/detail/replenish/record', // 配货记录
  teamTarget: '/team/detail/target', // 目标信息
  signalInfo: '/team/detail/indicator', // 关键指标信息
  agentLIst: '/agency/list',// 经销商信息
  agentBasic: '/agency/detail/basic', // 经销商基础信息
  agentReturnMoney: "/agency/detail/rebate/history",// 经销商返利历史
  agentTotal: "/agency/overview", // 经销商合计信息
  exportAgent: '/export/agencyList',//经销商导出
  exportPoint: '/export/pointList',//网点导出
  exportGroup: '/export/teamList',//小组导出
  logList: '/branch/log/list',//日志列表
  addLog: '/branch/log/add', // 添加日志1
  agencyOrder: '/dashboard/getAgencyPointRanking',// 经销商签约网点排名
  signalTarget: '/dashboard/getTeamPointCompleteRatio', // 小组签约目标完成度
  teamSignalPoint: '/dashboard/getTeamPointRanking', // 小组签约网点排名
  basicInfo: '/dashboard/branchinfo/get',// 基本信息
  signalTrend: '/dashboard/sign/trend', //签约趋势
  getTypeTeam: '/dashboard/money/rank', // 第三模块小组排名
  lineTrend: '/dashboard/trend/get', // 第三模块趋势图
  areaOrder: '/dashboard/area/rank', // 区域排名
  activityList: '/activity/listEngageNames',// 获取活动列表
  scanList: '/activity/listByCondition', //活动列表信息
  exportActivity: '/export/activityList', //导出活动列表
  msgPush: '/msgPush/listByCondition',//消息推送
  agencyBond: '/bond/list',// 经销商保证金明细
  merchantList: '/clueManager/listClueByCondition',//招商管理列表
  createClue: '/clueManager/createClue',//创建新线索
  getClueDetail: '/clueManager/getClueDetail',//获取线索详情
  updateClue: '/clueManager/updateClue',//更新线索
  activityBoard: '/activitydashboard/allDate',// 活动看板
  addFlow: '/clueManager/addFollowInfo',// 添加跟進記錄
  signalSure: '/clueManager/updateClueStatus',// 签约
  linkPeople: '/clueManager/listOfUsers',// 关联列表
  distriFlow: '/clueManager/createFollower', // 分配跟进人
  checkFlow: '/clueManager/updateFollower',//通过打回
  exportClue: '/export/clueList',// 导出线索
  areaList: '/area/list',//获去区域信息
  return_moneyMsglist: '/statistic/weixinRebatePayFlow/list',//返利明细列表
  return_moneyMsgid: '/rebate/byId',//修改单条返利记录
  return_moneyOverview: '/statistic/weixinRebatePayFlow/overview',//返利数据概览
  return_moneyExport: 'statistic/weixinRebatePayFlow/list/export',//返利数据导出
  return_moneyPassword: '/rebate/verify',//返利数据修改密码校验
  importAll: 'lg/express/importOneByOne', //批量导入
  waitSend: 'lg/logistics/order/unSendList', //待发货列表
  getSku: 'lg/jdPro/listByCondition',// 获取SKU信息
  deleteSKU: 'lg/jdPro/delete',// 删除SKU
  addSKU: 'lg/jdPro/add',// 新增SKU
  return_moneyMsgViewlist: '/statistic/weixinRebatePayFlow/list2',//返利对账列表
  return_moneyViewExport: '/statistic/weixinRebatePayFlow/list2/export',//返利对账数据导出
  editAddress: '/branch/shipaddress/update',//修改收货地址
  getJdInfo: 'lg/jdPro/selectJdProduct',// 查看京东订单信息
  sureOrder: 'lg/jd/order',//京东下单
  exportOrder: 'lg/express/expressUnSendList',// 订单列表导出
  qqMap: 'https://apis.map.qq.com/',//QQ地图
  getIP: 'ws/location/v1/ip',//获取IP地址
  getLocal: 'ws/geocoder/v1/',//坐标转地址,坐标转地址
  updateLocal: '/branch/position/update',// 更新定位地址,
  cancelJD: 'lg/jd/cancel/',//取消京东下单
  jdFlow: 'lg/jd/queryTrace/', // 查询京东物流
  editFlow: 'lg/logistics/order/warehouse/modify',// 修改收货信息
  seeAllFlow: 'lg/logistics/progress',// 查询物流状态
  seeJdFlow: 'lg/jd/queryDetail/',// 查询京东发货详情
  productList: 'lg/product/queryProductAndPromotion', // 查询产品列表
  addOrder: 'lg/logistics/order/addSendOrderList', //添加发货单
  cancelOrder: 'lg/logistics/order/cancel', // 取消发货单
  configList: '/area/config/list',// 区域配置列表
  agentList: '/area/config/UserRoleAll', // 获取区域总监
  deleteConfig: '/area/config/delete', // 删除配置
  createConfig: '/area/config/addOrModify', // 创建修改配置
  moneyAdd: 'money/add', // 新增返利明细（T+1分账）
  moneyDelete: 'money/cancel/', // 删除返利明细（T+1分账）
  thirdSend: 'lg/logistics/order/businessSend',// 商家代发
  applyList: 'wf/applyRecord/admin/list',//申请列表
  exportApply: 'wf/applyRecord/admin/list/export',// 申请列表导出
  getDoLog: 'lg/logistics/operate/selectById',// 查询操作日志
  applyDetail: 'wf/applyRecord/admin/detail',// 申请详情
  qrdetail: 'wf/applyRecord/admin/qrdetail',// 二维码明细
  createqr: 'wf/applyRecord/admin/qr',// 生成二维码
  matterList: '/product/distribution/promotionList',// 物料列表
  allowOrder: '/branch/point/allowOrder',// 网点修改是否可下五码配货订单
  agreesFiveCode: '/branch/point/agrees', //查询当前网点的五码合同
  pointSwitch: '/branch/point/allowOrder',//是否允许配货开关
  returnOrder: '/orderReturn/list', //退货单列表
  editRemark: '/orderReturn/updateMemo',// 修改备注
  exportReturnOrder: '/orderReturn/export',// 退货单导出
  backMoneylist: '/rebate/rebateMoneyList',// T + 1 分账
  deleteGroup: '/team/deleteGroup',// 删除小组
  getUser: '/team/getUsers',// 获取所有用户
  getHead: '/team/getAreaer',// 获取所有区域总
  addGroup: '/team/addGroup',//新增小组
  groupInfo: '/team/detail',//小组详情
  groupBasic: '/team/newList',//配置小组列表
  c_userList: '/cuser/list',// C端用户列表
  c_orderList: '/cuser/orderListByUserId',// C端订单列表
  orderList: 'so/order/list',// 订单列表
  getBenefitPointName: 'so/order/getBenefitPointName',// 获取计收网点名称
  productConfigList: '/sctt/productSeries/getList',//产品配置列表
  productInfo: '/sctt/product/getList', // 产品列表
  addList: '/sctt/productSeries/create',//新增
  editProduct: '/sctt/productSeries/updateById',// 编辑
  infoList: '/sctt/partyInfo/getList',//主体信息列表
  addInfo: '/sctt/partyInfo/create',// 新增主体信息
  resetInfo: '/sctt/partyInfo/updateById',//作废
  contractList: '/sctt/contract/getAdminList',//
  groupUser: '/sctt/contractMember/retrieveList',
  minInfo: '/sctt/partyInfo/retrieveOne',// 主体信息
  productArr: '/sctt/productSeries/retrieve/all',// 产品系列
  customerList: '/sctt/point/retrieve/criteria',//客户列表
  getBond: '/sctt/contractDeposit/retrieve/criteria',// 获取保证金信息
  areaInit: '/sctt/point/retrieve/criteria/id',//地址信息初始化
  createContract: '/sctt/contract/createOrUpdate/admin',// 创建合同
  contractDetail: '/sctt/contract/retrieveById',//合同详情
  autoDistribute: '/ch/point/autoDistribution/detail',//自动配货
  updateConfig: '/ch/point/autoDistribution/update',//更新自动配货
  exportOrderList: '/so/order/exportOrderList',// 订单列表导出
  sureOrderList: '/so/order/unChecklist',//订单确认列表
  changeBenefitPoint: '/so/order/changeBenefitPoint',//订单确认修改网点
  simpleList: '/ch/point/simpleList',//简单的五码网点列表
  giftList: '/so/order/productList',// 赠品列表
  orderSure: '/so/order/confirmOrder',// 订单确认
  getChangeGiftDetail: "/so/order/getChangeGiftDetail",//修改赠品的修改内容
  cancelChangeGift: "/so/order/cancelChangeGift",//取消修改赠品
  orderCheckStatus: '/so/order/orderAwait',// 订单状态
  sureEdit: '/so/order/orderDetailModify',//赠品修改
  contractLog: '/core/opLog/retrieve/criteria',// 日志列表
  logInfo: '/lg/logistics/order/productState',// 订单管理物流信息
  getMaster: '/sctt/contract/retrieveAreaOwner',//查询区域总
  getRelation: '/sctt/salesManDisRel/retrieveList',//业务员经销商关系
  allSendList: '/lg/logistics/order/express/list',//发货明细表列表
  allExport: '/lg/express/expressOrderExport',
  deleteRoleRelation: '/sctt/salesManDisRel/deleteByUserPartId',//删除业务员经销商关系
  autoDistributionList: '/ch/point/autoDistribution/orderList',//自动配货订单列表
  autoDistributionErrorList: '/ch/point/autoDistribution/exceptionList',//自动配货订单列表
  autoDistributionErrorDispose: '/ch/point/autoDistribution/handleException',//自动配货-处理异常
  autoDistributionProductList: '/ch/point/productList',//有效产品列表
  pointManageList: '/api/ncenter/point/user/list',//网点下属人员
  allSalesman: '/wf/userPart/retrieveList',//所有业务员
  allDealer: '/sctt/point/retrieveDisList',//所有经销商
  salesManDisRelCorU: '/sctt/salesManDisRel/createOrUpdate',//新增或修改业务员和经销商之间的关系
  getAllArea: '/sctt/saleAreaConfig/retrieve/all',// 所有销售区域
  insertOrder: '/so/guanyi/updateCipher',// 导入加密订单
  manageList: '/api/ncenter/user/list',// 管理人员列表
  deleteUser: '/api/ncenter/user/del',//删除用户
  editUser: '/api/ncenter/user/edit',// 编辑用户
  saveUser: '/api/ncenter/user/save',// 保存用户
  setUnlock: '/api/ncenter/point/user/del',// 解除用户绑定关系
  editReal: '/api/ncenter/point/user/edit',// 修改绑定关系
  addReal: '/api/ncenter/point/user/add',// 新增绑定关系
  seeMoreInfo: '/api/ncenter/user/info',// 查看用户信息
  agreementState: '/branch/agreement/state',// 网点修改合同是否在待重签列表
  agreementBankinfo: '/branch/agreement/bankinfo',// 修改网点合同收款账户信息
  getTopAgency: '/sctt/point/retrieveDisList',//查询上级经销商列表
  whiteList: '/lg/product/businessSend/productList',
  sellList: '/area/distributor/list',// 经销商区域列表
  getPointList: '/point/getPointList',// 经销商区域列表
  distributorAdd: '/area/distributor/add',// 经销商区域新增
  distributorModify: '/area/distributor/modify',// 经销商区域修改
  distributorDelete: '/area/distributor/delete',// 经销商区域删除
  deleteWhite: '/lg/product/businessSend/deleteProduct',// 删除白名单
  whiteProductList: '/lg/product/queryProductAndPromotion',//商家代发白名单商品列表
  addWhiteProduct: '/lg/product/businessSend/addProduct',// 添加商品白名单
  submitUnpaidOrder: '/so/order/submitUnpaidOrder',// 经销商区域删除
  upload: '/wf/reimburse/receipt/upload',// 图片上传
  getPointRaffleRecord: '/raffle/ncenter/getPointRaffleRecord',// 查询网点潭乐透奖券流水
  stockCheckList: '/api/ncenter/stockCheck/list',// 网点库存盘点任务列表
  stockCheckDetail: '/api/ncenter/stockCheck/detail',// 网点库存盘点任务列表
  getOrdersByPointId: '/api/ncenter/order/getOrdersByPointId',// 查询网点下订单
  allocate: '/api/ncenter/allocate/data',// 查询网点下订单
  banquetList: '/rest/tool/banquet/order/list',// 获取网点宴席订单列表
  orderDetail: '/api/ncenter/order/getOrderInfoById',// 获取订单详情
  orderProduct: '/api/ncenter/order/products',// 获取订单详情商品列表
  orderDeliverys: '/api/ncenter/order/deliverys',// 获取订单发货物流信息
  pointCover: '/api/ncenter/point/cover/statistics',// 网点区域覆盖
  orderQrcodes: '/api/ncenter/order/products/qrcodes',// 查看订单下指定商品的五码
  applyReportList: 'wf/applyReport/pageList',// 费效分析报表
  applyReportOut: 'wf/applyReport/pageListOut',// 费效分析报表导出
  businessAreaExport: 'api/ncenter/point/cover/statistics/export',// 网点区域覆盖导出
  pointSaleStatistics: 'api/ncenter/point/sale/statistics',// 网点销售统计
  SapStockSendList: '/so/guanyi/stockSendList',//SAP发货列表
  SapreSentStockSend: '/so/guanyi/reSentStockSend',//SAP重新推送
  signalInfoData: '/wf/applyRecord/apply/signInList',// 签到信息
  giftManageList: '/product/config/list',// 赠品管理列表
  deleteGift: '/product/config/delete',// 删除赠品
  addGift: '/product/config/add',//新增赠品
  getAgreentItemsStatisticsOfTheAgentPoints: '/api/ncenter/agent/sale/getAgreentItemsStatisticsOfTheAgentPoints',// 经销商&门店产品销售报表
  getOrdersStatisticsOfTheAgentPoints: '/api/ncenter/agent/sale/getOrdersStatisticsOfTheAgentPoints',// 经销商&网点销售订单报表
  getOrderUpLoadPriceDifference: '/so/order/getOrderUpLoadPriceDifference',// 获取订单上传差价凭据信息
  agentSaleStatistics: 'api/ncenter/agent/sale/statistics', //经销商销售统计
  agentPointList: 'api/ncenter/agent/pointList', //经销商下属网点列表
  agentListPhp: 'api/ncenter/agent/list', //经销商列表
  agentDetail: 'api/ncenter/agent/detail', //经销商详情
  agentContract: 'api/ncenter/agent/contract', //经销商签约信息（合同列表）
  agentMarketing: 'api/ncenter/agent/marketing/expenses', //经销商营销费用列表
  skuList: '/product/config/getSKUList',// 前后台转换关系列表
  deleteSku: '/product/config/deleteSKU',// 前后台
  addRelation: '/product/config/addSKU',// 新建关系
  nameSearchTeam: '/branch/detail/search',// 通过姓名搜索小组列表
  updateMainUser: '/branch/detail/updateMainUser',// 修改网点维护人
  firstPartlist: '/pc/firstPart/list',// 对公账户开户行列表
  firstPartDetail: '/pc/firstPart/detail',// 甲方详情
  firstPartAreaNameVo: '/pc/firstPart/AreaNameVo',// 主体的区域管理-列表
  getWalletHis: '/pc/allinpay/getWalletHis',// 获取钱包流水
  boundBankCardList: '/uc/tl/boundBankCardList',// 支付订单列表--成功完成
  firstPartAddArea: '/pc/firstPart/addArea',// 主体的区域管理-新增
  firstPartDelArea: '/pc/firstPart/delArea',// 主体的区域管理-删除
  publicBankList: '/pc/firstPart/publicBankList',// 对公账户开户行列表
  firstPartCreate: '/pc/firstPart/create',// 主体新增
  rebateMoneyListExport: '/rebate/rebateMoneyListExport',// 返利列表导出
  queryInExpDetail: '/rebate/queryInExpDetail',// 通联返利列表导出
  pointListPhp: 'api/ncenter/point/list',// 网点列表
  pointDownload: 'api/ncenter/point/list/download',// 网点列表
  jdStore: '/lg/jd/getWarehouse',//京东仓库
  expenseExport: '/rebate/listOrder/export',// 导出财务专属订单列表
  orderMerge: '/so/order/checkAndGetOrderMergeInfo',// 订单合并
  submitBigOrderMerge: '/so/order/submitBigOrderMerge',// 订单合并
  saleUserlist: 'api/ncenter/saleUser/list',// 员工管理列表
  rolelistPHP: 'api/ncenter/role/list',// 角色列表
  departmentList: 'api/ncenter/department/list',// 部门列表
  saleUserDetail: 'api/ncenter/saleUser/detail',// 员工详情
  areaManage: 'api/ncenter/saleUser/areaManage/list',// 业务员区域管理列表
  saleUserPoint: 'api/ncenter/saleUser/point/list',// 业务员维护销售网点列表
  saleUserAgent: 'api/ncenter/saleUser/agent/list',// 业务员维护经销商列表接口
  departmentStatistics: 'api/ncenter/department/statistics',// 部门管理列表-含统计
  departmentDetail: 'api/ncenter/department/detail',// 部门管理列表-含统计
  saleUserAreaManage: 'api/ncenter/saleUser/areaManage/list',// 业务区区域管理列表
  saleUserSuperiors: 'api/ncenter/saleUser/superiors',// 业务区区域管理列表
  areaListPHP: 'api/ncenter/area/children',// 五级地区列表
  saleUserAddArea: 'api/ncenter/saleUser/areaManage/add',// 业务员区域维护
  commentList: '/api/comment/list',// 网点列表
  list_by_name: 'api/ncenter/point/list_by_name',// 根据网点名称查询网点列表
  commentDel: '/api/comment/del',// 删除评论
  commentNodel: '/api/comment/nodel',// 取消删除评论
  commentTop: '/api/comment/top',// 置顶评论
  commentNotop: '/api/comment/notop',// 取消置顶
  commentSelected: '/api/comment/selected',// 精选评论
  commentNoselected: '/api/comment/noselected',// 取消精选
  commentCreate: '/api/comment/create',// 回复评论
  commentOne: '/api/comment/one',// 获取某一条评论详情及其回复
  commentReplys: '/api/comment/replys',// 获取回复列表
  list_by_area: '/api/ncenter/point/list_by_area',// 根据省市区查询网点列表
  tastsignDetail: '/wf/applyRecord/apply/signInList',//品鉴签到、 红包信息
  deliverEnable: 'api/ncenter/point/deliver/enable',// 网点、经销商修改是否可配货状态
  channelPin: 'api/ncenter/panel/channel/sale/statistics',// 渠道动销看板
  getApplyStatics: '/api/ncenter/panel/saleStatics/getApplyStatics',// 渠道动作--小品会&amp;联谊会数据统计
  getBanquetStatics: '/api/ncenter/panel/saleStatics/getBanquetStatics',// 宴席数据统计
  channelSign: 'api/ncenter/panel/channel/sign/statistics',// 渠道签约看板
  getShopsOrdersStatics: '/api/ncenter/panel/saleStatics/getShopsOrdersStatics',// 商城动销统计
  getOrdersGoodsStatics: '/api/ncenter/panel/saleStatics/getOrdersGoodsStatics',// 商品销售看板
  overviewSales: 'api/ncenter/panel/overview/sales',// 动销看板-概览-动销
  overviewBanquet: 'api/ncenter/panel/overview/banquet',// 动销看板-概览-宴席
  overviewBusiness: 'api/ncenter/panel/overview/business_sign',// 动销看板-概览-签约
  sales_product_number: 'api/ncenter/panel/overview/sales_product_number',// 动销看板-概览-产品系列贡献
  sales_channel: 'api/ncenter/panel/overview/sales_channel',// 动销看板-概览-动销渠道分布
  sales_areas: 'api/ncenter/panel/overview/sales_areas',// 动销看板-概览-区域动销统计
  concatOrder: '/so/banquetRelated/mergeToJoinBanquet',// 合并参加宴席
  convertConcat: '/so/banquetRelated/giftBanquetConvert',// 订单关系转换
  refundList: '/so/refund/refundList',// 退款列表
  getPaidInfo: '/so/order/getPaidInfo',// 退款列表
  refundApply: '/so/refund/apply',// 退款列表
  refundDetail: '/so/refund/refundDetail',// 退款详情
  refundPreApply: '/so/refund/preApply',// 预取消信息
  refundCancelRefund: '/so/refund/cancelRefund',// 撤销申请退款
  addBankTransPayData: '/so/refund/addBankTransPayData',// 增加银行退款记录
  tagList: 'api/tag/list',// 标签列表
  tagCategory: 'api/tag/category/keywords',// 标签列表
  tagDetail: '/api/tag/detail',// 标签详情
  tagTreeList: 'api/tag/category/treeList',// 标签分类树
  tagClassDetail: '/api/tag/cate/detail',// 标签分类详情
  targetList: '/api/tag/target/list',// 标签对象列表
  seeElcContract: '/sctt/contract/preview',// 查看电子合同
  jdErrorData: '/lg/jd/queryJdExceptionList',// 京东单异常数据
  sureResolve: '/lg/jd/confirmException',// 京东异常确认解决
  orderLogistic: '/so/logistic/detail',// 订单的发货信息
  consumerList: 'api/consumer/list',// 消费者列表
  consumerInfo: 'api/consumer/detail',// 消费者详情
  consumerAuths: '/api/consumer/auths',// 消费者授权信息
  consumerAddresses: '/api/consumer/addresses',// 消费者收货地址
  costStatistics: '/api/ncenter/consumer/costStatistics',// 消费者消费统计
  consumerOrders: '/api/ncenter/consumer/getOrders',// 消费者订单
  consumerScoreLogs: '/api/ncenter/consumer/scoreLogs',// 用户积分流水日志
  getOrderByUserId: '/so/order/getOrderByUserId',// 根据userId查询用户消费订单列表
  getGoStore: '/lg/orderRefund/orderReturnList',// 查询入库数据
  editStoreRemark: '/lg/orderRefund/editRemark',// 修改备注
  exportGoStore: '/lg/orderRefund/orderReturnListExport',//导出入库列表
  financeExport: '/statistic/statistic/unifiedOrderRebate/list/export',// 财务收入明细数据导出
  showInfoOrder: '/so/order/modify/getOriginDetail',// 订单修改信息获取
  getRebateRule: '/sctt/contract/showRelationList',// 查看返利规则
  contractRule: 'sctt/contract/getPointRebate',// 获取合同下的返利规则
  productListPhp: '/api/product/list',//
  productSeriesList: '/api/product/series/list',//
  productSeriesAdd: '/api/product/series/add',//
  productSeriesDelete: '/api/product/series/delete',//
  productSeriesDetail: '/api/product/series/detail',//
  productSeriesEdit: '/api/product/series/edit',//
  addOrRemoveToSeries: '/api/product/addOrRemoveToSeries',//
  setOnlineStatus: '/api/product/setOnlineStatus',// 商品上下架
  productSetSort: '/api/product/setSort',// 商品排序
  productCreateOrEdit: '/api/product/createOrEdit',// 商品修改
  productDetailInfo: '/api/product/info',// 商品详情
  productSkuList: '/api/product_sku/list',// 商品的sku
  product_spec: '/api/product_spec/list',//
  skuAddOrUpdate: '/api/product_sku/addOrUpdate',// 修改sku
  skuStatus: '/api/product_sku/online_status',// sku状态
  skuMain: '/api/product_sku/main',//
  skuDel: '/api/product_sku/del',//
  skuDetail: '/api/product_sku/detail',//
  simplePointList: '/api/ncenter/point/base_list',//
  productPoints: '/api/product/points',//
  productOnlineSetting: '/api/product/onlineSetting',//
  productSkuSort: '/api/product_sku/sort',// sku排序
  productDelete: '/api/product/delete',// 删除商品
  putOssFile: '/api/common/put_oss_file',// 上传接口统一
  validateOnline: '/api/product/validateOnline',// 产品上架验证
  getSap: '/api/product_sku/sap',// 通过sap码获取sap信息
  checkAuthority: '/so/banquetRelated/getAndCheckOrders',// 判断订单合并权限
  lgReportForm: '/lg/logistics/reportForm/Kanban',// 物流中心看板（报表）
  lgFiveUnSendRatio: '/lg/logistics/reportForm/product/FiveUnSendRatio',// 物流中心看板-五码待发比例
  banquetDetail: '/tools/banquet/detail',// 宴席详情
  banquetProductQR: '/tools/banquet/get_product_count_list_by_pro_id',// 宴席产品二维码
  banquetQR: '/tools/banquet/get_product_count_list_by_smoke_code',// 宴席详情
  banquetSendAward: '/tools/banquet/send_award',// 奖励重新发放
  banquetOrderList: '/tools/banquet/order_list',// 宴席订单列表
  rebateList: '/rebate/groupAgentYearSettle/list',// 团购经销商返利列表
  exportGroupRebate: '/rebate/groupAgentYearSettle/export',// 导出团购经销商年终返利
  codeDetailInfo: '/lg/orderRefund/detail',
  rebateType: '/rebate/common/getRebateTypeMap',
  payDetail: '/rebate/taste/getDedRef',// 支付信息
  returnOrderList: '/lg/orderRefund/returnOrderList',//入库列表
  returnOrderInfo: '/lg/orderRefund/returnOrderInfo',//入库明细
  stsProductList: '/lg/product/productList',//查询总产品列表
  addReturnOrder: '/lg/orderRefund/addReturnOrder',//新增入货单
  cancelReturnOrder: '/lg/orderRefund/cancelReturnOrder',//取消入货单
  warehouseStock: '/lg/warehouseStock/export',//仓库导出
  demonstrateList: '/api/ncenter/demonstrate/list',// 演示列表
  demonstrateProduct: 'api/ncenter/demonstrate/product',// 通过码获取商品信息
  demonstratePoint: 'api/ncenter/point/demonstrate_list',// 获取可添加为演示的网点及上级经销商
  demonstrateAE: 'api/ncenter/demonstrate/edit',// 添加、编辑演示
  demonstrateDetail: 'api/ncenter/demonstrate/detail',// 演示详情
  demonstrateDel: 'api/ncenter/demonstrate/del',// 删除演示
  demonstrateProcess: 'api/ncenter/demonstrate/process/back',// 演示进度修改
  activity_list: 'api/ncenter/merchants_clues/activity_list',// 招商活动列表
  activity_detail: 'api/ncenter/merchants_clues/activity_detail',// 招商活动详情
  create_activity: 'api/ncenter/merchants_clues/create_activity',// 添加
  del_activity: 'api/ncenter/merchants_clues/del_activity',// 删除
  window_list: 'api/ncenter/merchants_clues/window_list',// 报名窗口列表
  create_window: 'api/ncenter/merchants_clues/create_window',// 报名窗口创建
  edit_window: 'api/ncenter/merchants_clues/edit_window',// 报名窗口编辑
  del_window: 'api/ncenter/merchants_clues/del_window',// 删除报名窗口
  merchantsTag_list: 'api/ncenter/merchants_clues/tag_list',// 标签列表
  merchantsPoint_list: 'api/ncenter/merchants_clues/point_list',// 通行点列表
  merchantsCreate_point: 'api/ncenter/merchants_clues/create_point',// 创建通行点
  merchantsEdit_point: 'api/ncenter/merchants_clues/edit_point',// 编辑通行点
  merchantsDel_point: 'api/ncenter/merchants_clues/del_point',// 删除通行点
  merchantsChannel_list: 'api/ncenter/merchants_clues/channel_list',// 渠道列表
  merchantsCreate_channel: 'api/ncenter/merchants_clues/create_channel',// 创建渠道
  merchantsEdit_activity: 'api/ncenter/merchants_clues/edit_activity',// 编辑活动
  merchantsEdit_channel: 'api/ncenter/merchants_clues/edit_channel',// 编辑渠道
  tc_invitation_list: '/api/tool/invitation/tc_invitation_list',// 邀请报名列表
  tc_check_list: '/api/tool/invitation/tc_check_list',// 通行记录
  tc_invitation_detail: '/api/tool/invitation/tc_invitation_detail',// 邀请报名详情
  submitOrderEdit: '/so/order/modify/submit',// 提交订单修改
  imitateLoginList: '/api/ncenter/imitateLogin/list',// 模拟登陆配置列表
  imitateLoginAdd: '/api/ncenter/imitateLogin/add',// 添加配置
  imitateLoginDetail: '/api/ncenter/imitateLogin/detail',// 配置详情
  imitateLoginCode: '/api/ncenter/imitateLogin/code',// 配置生成验证码
  syncGuanyiyun: '/api/product_sku/sync/guanyiyun',// 同步sku到管易云
  importBatch: '/lg/express/importBatch',// 导入商家代发物流单号
  terminationList: '/sctt/contractTermination/termination/list',//查询合同解约列表
  startDingSubmit: '/sctt/contractTermination/manager/startDingSubmit',//【运营钉钉】-发起钉钉审批
  backLastStep: '/sctt/contractTermination/termination/backLastStep',//返回上一步
  reApproval: '/sctt/contractTermination/termination/reApproval',//重新发起钉钉审批
  tc_banquet: '/api/banquet/tc_banquet',//TC后台宴席的数据
  tc_banquet_update: '/api/banquet/tc_banquet_update',//TC后台宴席审核
  bizTypeOption: '/sbat/tc/basicConfig/bizType/option',//获取基础资料配置字段分类下拉
  getBaseDataList: '/sbat/tc/basicConfig/dataList',//获取基础资料配置列表信息
  changeBasicConfig: '/sbat/tc/basicConfig',//基础资料生效失效操作
  getLevelTypeDataList: '/sbat/tc/levelType/dataList',//网点等级配置列表
  getMaterialConfigDataList: '/sbat/tc/materialConfig/dataList',//物料配置列表
  changeMaterialConfig: '/sbat/tc/materialConfig',//物料配置生效失效操作
  getMaterialPackOption: '/sbat/tc/materialPack/option',//物料包配置-下拉选择
  getMaterialPackDataList: '/sbat/tc/materialPack/dataList',//物料包配置列表
  getMaterialApplyDataList: '/sbat/tc/materialApply/dataList',//物料申请列表
  getMaterialApplyDetailDataList: '/sbat/tc/materialApply/detail/dataList',//物料申请明细列表
  getMaterialApplyDetailExport: '/sbat/tc/materialApply/detail/export',//物料申请明细列表导出
  getMaterialApplyExport: '/sbat/tc/materialApply/export',//物料申请列表导出
  materialApplyPurchase: '/sbat/tc/materialApply/purchase',//批量采购入库
  agentSelfPoint: 'api/ncenter/agent/selfPoint/edit',//更新经销商自有网点
  quotaList: '/zeusDms/get/quota/list',//根据网点ID查询配额列表
  quotaDetailList: '/zeusDms/get/quota/detail/list',//根据配额ID查询配额详情
  chContractList: '/ch/point/distribute/contract/list',//根据配额ID查询配额详情
  chProductList: '/ch/point/contract/product/list',//根据合同ID获取产品列表
  getDistributorExcel: 'sctt/contract/getDistributorExcel',//经销商合同导出
  getRestaurantListExcel: 'sctt/contract/getRestaurantListExcel',//餐饮店合同导出
  retryCreatePartySplit: '/pc/firstPart/retryCreatePartySplit',//计算返利
  retryReturnPartySplit: '/pc/firstPart/retryReturnPartySplit',//返利给 烟酒店、团购经销商
  retryReturnPartyBSplit: '/pc/firstPart/retryReturnPartyBSplit',//返利给全渠道经销商
  getCheckMissInfo: 'check/miss/info', //获取盘亏支付的信息
  handelMissStock: 'check/update/stock', //盘亏处理
  getTiTokRankInfo: 'tc_relate/get_tiktok_img', //获取抖音排名图片
  setTiTokRankInfo: 'tc_relate/set_tiktok_img', //修改抖音排名图片
  getPageSubDeptByDeptId: '/user-center-final/dept/getPageSubDeptByDeptId', //大区管理、省办管理、城市办管理 部门列表查询
  editDeptAttr: '/user-center-final/deptAttr/editDeptAttr', // 编辑部门属性
  getSeriesList: 'user-center-final/product/getSeriesList', // 查询产品系列下拉列表值
  cityAreaList: '/user-center-final/deptAttr/getDeptAreaByDeptId', // 根据部门id获取部门的地域属性map

  // 分页查询指标库
  indicatorList: '/manageTarget/admin/indicator/list',
  // 新增指标
  addIndicator: '/manageTarget/admin/indicator/create',
  // 编辑指标
  editIndicator: '/manageTarget/admin/indicator/update',
  // 删除指标
  deleteIndicator: '/manageTarget/admin/indicator/delete',
  // 中台/后勤分页查询计划目标列表
  getPlanList: '/manageTarget/admin/plan/list',
  // 大区总/省总 分页查询目标列表
  getTargetList: '/manageTarget/admin/target/list',
  // 新增计划目标
  createPlan: '/manageTarget/admin/plan/create',
  // 编辑计划目标
  editPlan: '/manageTarget/admin/plan/update',
  // 获取达成值配置的数据中心code
  indicatorsCodes: '/manageTarget/admin/plan/indicatorsCodes',
  // 删除计划
  deletePlan: '/manageTarget/admin/plan/delete',
  // 下载计划模板
  downloadPlan: '/manageTarget/admin/plan/download',
  // 批量导入目标
  batchImportTarget: '/manageTarget/admin/plan/import',
  // 查看导入详情
  getImportRecords: '/manageTarget/admin/target/import/records',
  // 根据计划id查询目标详情
  getDetailById: '/manageTarget/admin/target/detail',
  // 根据本周 或筛选周获得目标详情数据
  getDetailByWeek: '/manageTarget/admin/target/weekRecordSummary',
  // 批量导出目标
  batchExportTarget: '/manageTarget/admin/target/export',
  // 批量编辑目标
  batchUpdateTarget: '/manageTarget/admin/target/updateTarget',
  // 批量删除目标
  batchDeleteTarget: '/manageTarget/admin/target/batchDelete',
  // 领取目标
  receivingTarget: '/manageTarget/admin/target/receiving',
  // 获取目标详情 合计提醒
  getTargetTip: '/manageTarget/admin/target/tip'


};
