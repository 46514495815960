<template>
  <div class="statistic-col">
    <div ref="chartEl" style="height: 100%; width: 100%"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      echartOption: {
        // backgroundColor: '', //设置整体背景色
        // color: ['#8378EA','#9C97F2','#92C0FF','#EAB9F7','#E78FD4','#DC63AE','#F77294','#F99E82','#FDDB5C','#399FE1','#3BC6E6','#67E1E3','#ACDEBD'],
        color: [
          "#7D1EDA",
          "#F87C4C",
          "#FCD037",
          "#208FFF",
          "#3DC5C4",
          "#F8999D",
          "#42C800",
          "#F64681",
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [],
            type: "bar",
          },
        ],
      },
    };
  },
  props: ["data"],
  methods: {
    drawLine() {
      // 内容授权统计
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(this.$refs.chartEl);
      // 绘制图表
      myChart.setOption(this.echartOption);
    },
    // 获取内容授权数据
    getListData() {
      let myChart = echarts.getInstanceByDom(this.$refs.chartEl);
      myChart.clear();
      let renderDatas = [];
      let xName = [];
      let legendName = [];
      for (let index = 0; index < this.data.length; index++) {
        xName = [];
        legendName.push(this.data[index].name);
        let item = [];
        for (let x = 0; x < this.data[index].tableData.length; x++) {
          item.push(this.data[index].tableData[x].total_price / 1);
          xName.push(this.data[index].tableData[x].group_name);
        }
        renderDatas.push({
          name: this.data[index].name,
          type: "bar",
          data: item,
        });
      }
      myChart.setOption({
        color: [
          "#7D1EDA",
          "#F87C4C",
          "#FCD037",
          "#208FFF",
          "#3DC5C4",
          "#F8999D",
          "#42C800",
          "#F64681",
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        title: {
          subtext: "动销金额",
        },
        legend: {
          data: legendName,
        },
        xAxis: {
          type: "category",
          data: xName,
          axisLabel: {
            interval: 0,
          },
        },
        yAxis: {
          type: "value",
          name: "（元）",
        },
        series: renderDatas,
      });
    },
  },
  mounted() {
    this.drawLine();
    let myChart = echarts.getInstanceByDom(this.$refs.chartEl);
    window.addEventListener("resize", () => {
      myChart.resize();
    });
  },
  watch: {
    data: {
      handler(newName, oldName) {
        this.$nextTick(() => {
          this.getListData();
        });
      },
      deep: true,
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      myChart.resize();
    });
  },
};
</script>
<style lang="scss" scope>
.statistic-col {
  height: 600px;
}
</style>
